// src/components/RetrieveText.tsx
import React, { useState, useEffect } from 'react';
import { useParams, useLocation, Link } from 'react-router-dom';
import { fetchText, fetchTextByCode } from '../api/textApi';
import { Container, Typography, CircularProgress, Alert, TextField, Box, Button, Paper } from '@mui/material';
import axios from 'axios';
import ResizableTextField from './ResizableTextField';

const RetrieveText: React.FC = () => {
  const { id } = useParams<{ id?: string }>();
  const location = useLocation();
  const [text, setText] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [copySuccess, setCopySuccess] = useState<string | null>(null);
  const [shortCode, setShortCode] = useState<string>('');

  useEffect(() => {
    if (id) {
      const getText = async () => {
        setLoading(true);
        setError(null);
        try {
          const retrievedText = await fetchText(id);
          setText(retrievedText);
        } catch (error) {
          if (axios.isAxiosError(error) && error.response?.status === 404) {
            setError('Nothing here...');
          } else {
            setError('Error retrieving the text');
          }
        } finally {
          setLoading(false);
        }
      };

      getText();
    }
  }, [id]);

  const handleFetchByCode = async () => {
    setLoading(true);
    setError(null);
    try {
      const retrievedText = await fetchTextByCode(shortCode);
      setText(retrievedText);
    } catch (error) {
      if (axios.isAxiosError(error) && error.response?.status === 404) {
        setError('Nothing here...');
      } else {
        setError('Error retrieving the text');
      }
    } finally {
      setLoading(false);
    }
  };

  const handleCopy = async () => {
    if (text) {
      try {
        await navigator.clipboard.writeText(text);
        setCopySuccess('Text copied to clipboard!');
      } catch (error) {
        setCopySuccess('Failed to copy text.');
      }
    }
  };

  if (loading) {
    return (
      <Container>
        <CircularProgress />
      </Container>
    );
  }

  if (error) {
    return (
      <Container>
        <Alert severity="error">{error}</Alert>
      </Container>
    );
  }

  return (
    <Container>
      <Typography variant="h4" component="h1" gutterBottom>
            Your Text
      </Typography>
      {text ? (
        <Box className="content" sx={{ width: '98%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
        <Paper elevation={3} sx={{ p: 3, width: '100%', maxWidth: 1200 }}>
          <ResizableTextField
          value={text || ''}
          InputProps={{
            readOnly: true,
          }}
          sx={{
            whiteSpace: 'pre-wrap',
            wordBreak: 'break-word',
          }}
        />
          <Button
            variant="contained"
            color="primary"
            onClick={handleCopy}
            disabled={!text}
            sx={{ marginTop: 2 }}
          >
            Copy Text
          </Button>
          {copySuccess && (
            <Alert severity="success" sx={{ marginTop: 2 }}>
              {copySuccess}
            </Alert>
          )}
        </Paper>
        <Box sx={{ mt: 3 }}>
          <Typography variant="body1">
            Have something to send? <Link to="/">Send your info here</Link>
          </Typography>
        </Box>
        </Box>
      ) : (
        <Box>
          <TextField
            label="Enter Short Code"
            variant="outlined"
            fullWidth
            margin="normal"
            value={shortCode}
            onChange={(e) => setShortCode(e.target.value)}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={handleFetchByCode}
            sx={{ marginTop: 2 }}
          >
            Fetch Text
          </Button>
        </Box>
      )}
    </Container>
  );
};

export default RetrieveText;